import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import DeliveryTypeScreen from './DeliveryTypeScreen';
import footerImg from '../../assets/footerLogo.png';
import Menubar from './Menubar';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateWebShopMenuPosition } from '../../store/slice/widgetSetting.slice';

const WebshopMain = () => {
  const location=useLocation();
  const dispatch=useDispatch()


  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const position = queryParams.get("position");
    dispatch(updateWebShopMenuPosition(position))
  },[location])
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#EBEBEB',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column', 
      }}
    >
        {/*menu item */}
       
      {/* Main Content */}
      <Box sx={{ flex: 1, pt: 5 }}> 
        <DeliveryTypeScreen />
      </Box>

      {/* Footer Section */}
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row', // Always keep it row
    flexWrap: 'wrap', // Allow wrapping for responsiveness
    gap: '1rem', // Reduce gap for small screens
    alignItems: 'center',
    justifyContent:{md:"flex-start",sm:"center"} , // Center align on smaller screens
    px: { xs: 2, md: 4 },
    py: 2,
  }}
>
  <Box
    sx={{
      flex: '0 1 auto', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
    }}
  >
    <img
      src={footerImg}
      alt="footerLogo"
      style={{ minWidth: '76px', height: 'auto' }}
    />
  </Box>

 
  <Typography
    sx={{
      flex: '1 1 auto', // Allow the text to grow/shrink
      color: '#969BA0',
      fontSize: '12px',
      textAlign: 'justify',
      maxWidth: { xs: '100%', sm: '70%' }, // Adjust width for small screens
      wordBreak: 'break-word', // Handle long text gracefully
    }}
  >
    Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
    consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
    Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam
    mauris et aliquet sit commodo sagittis.
  </Typography>
</Box>

    </Box>
  );
};

export default WebshopMain;

