import { useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import lang from "../../assets/lang.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";

const MenuComponent = ({ MenuItems }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const scrollContainerRef = useRef(null); // Reference to the scroll container

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is small
  const languageData=[
    {
      langcode:'en',
      language:"English",
      idlanguage:"English",
    },
    {
      langcode:'fr',
      language:"Français",
      idlanguage:"French",
    }
  ]
  const [defaultLanguage,setDefaultLanguage] = useState("EN")

  const handleNextScroll = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 120, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "120px",
        pt: 2,
        display:"flex",
        justifyContent:'space-between',
        alignItems:'center',
       
      }}
    >
      {/* Menu Items */}
      <Box
        sx={{
          display: "flex",
          position: "relative",
          width: isSmallScreen ? "100%" : "80%", // Full width on small screens
          overflowX: isSmallScreen ? "auto" : "hidden", // Enable horizontal scroll on small screens only
          paddingBottom: "10px",
        }}
      >
        <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: isSmallScreen ? "2rem" : "4rem",
            minWidth: "max-content", // Allow items to overflow
            flexShrink: 0, // Prevent items from shrinking
            scrollBehavior: "smooth", // Smooth scrolling behavior
            overflowX: "hidden", // Hide overflow for larger screens
          }}
        >
          {MenuItems?.map((item, i) => (
            <Box
              key={i}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom:
                  selectedMenuItem === item?.name
                    ? "3px solid #F37120"
                    : "transparent",
                marginRight: "16px", // Space between items
              }}
              onClick={() => setSelectedMenuItem(item?.name)}
            >
              <Box
                sx={{
                  margin: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 80,
                  height: 80,
                  backgroundColor:
                    selectedMenuItem === item?.name ? "#F37120" : "transparent",
                  borderRadius: "16px",
                }}
              >
                <img
                  src={item.imgItem}
                  alt={item.name}
                  width="50"
                  height="50"
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    color: selectedMenuItem === item?.name ? "#fff" : "#969BA0",
                    fontSize: "11px",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {!isSmallScreen && 
      // <Box sx={{mr:2}}>
      //   <FormControl
      //     variant="outlined"
      //     sx={{
      //       minWidth: 120,
      //       backgroundColor: theme?.widget?.langBgColor || '#fff',
      //       width: { xs: "100px", md: "120px" },
      //       height: "45px",
      //       borderRadius: "5px",
      //       border:"1px solid #969BA0",
           
      //     }}
      //   >
      //     <Select
      //       value={defaultLanguage || 'NL'}
      //      // onChange={handleLanguageChange} // Set the onChange handler here
      //       // IconComponent={() => (
      //       //     <KeyboardArrowDownIcon
      //       //         sx={{ color: "black", mr: 1 }}
      //       //     />
      //       // )}
      //       displayEmpty
      //       renderValue={() => (
      //         <Box sx={{ display: "flex", alignItems: "center" }}>
      //           <img
      //             src={lang}
      //             alt={"lang-img"}
      //             style={{ width: "40px", marginRight: "10px" }}
      //           />
      //         </Box>
      //       )}
      //       sx={{
      //         "& .MuiOutlinedInput-notchedOutline": {
      //           borderColor: "transparent",
      //         },
      //         "&:hover .MuiOutlinedInput-notchedOutline": {
      //           borderColor: "#transparent",
      //         },
      //         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      //           borderColor: "transparent",
      //         },
      //         "& .MuiOutlinedInput-input": {
      //           padding: 1,
      //           //     color: "#9F9F9F",
      //           fontSize: "12px",
      //         },
      //       }}
      //     >
      //       {languageData?.map((item, index) => (
      //         <MenuItem
      //           key={item?.idlanguage}
      //           value={item?.langcode}
      //           sx={{
      //             color: theme?.palette?.widget?.langTextColor || 'grey', // Text color of MenuItem
      //             "&.Mui-selected": {
      //               backgroundColor: theme?.widget?.langActiveBgColor || "orange", // Highlight selected item
      //             },
      //             "&:hover": {
      //               backgroundColor: theme?.widget?.langBgHover || "orange", // Hover effect for MenuItem
      //               color: theme?.widget?.langTextHover || "#fff",
      //               "&.Mui-selected": {
      //                 backgroundColor: theme?.widget?.langBgHover || "orange", // Hover effect for MenuItem
      //                 color: theme?.widget?.langTextHover || "#fff",
      //               },
      //             },
      //           }}
      //         >
      //           {(item?.langcode == defaultLanguage?.trim()) != "" ? (
      //             <>
      //               {item?.language}
      //               <CheckBoxIcon
      //                 sx={{
      //                   color: theme?.widget?.langCheckboxActive,
      //                   marginRight: "8px",
      //                   ml: 2,
      //                 }}
      //               />
      //             </>
      //           ) : (
      //             item?.language
      //           )}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //   </FormControl>
      // </Box>
      <LanguageMenuItem />
      }
    </Box>
  );
};

export default MenuComponent;
