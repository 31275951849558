import { Box } from '@mui/material'
import React from 'react'
import Menubar from './Menubar'
import MenuItemListComponent from './MenuItemListComponent'
import { useSelector } from 'react-redux'

const MenuComponent = () => {
    const {webShopMenuPosition}=useSelector((store)=>store?.widgetSettings)
  return (
    <Box sx={{display:webShopMenuPosition == 'vertical' ? "flex" : 'block',backgroundColor:"#EBEBEB",height:"auto",minHeight:"100vh"}}>
      <Menubar />
      {webShopMenuPosition  && <MenuItemListComponent />}
    </Box>
  )
}

export default MenuComponent
