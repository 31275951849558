import { Box, Button, Grid2, Typography } from '@mui/material';
import React, { useState } from 'react';
import burger from "../../assets/menuBurger.png";
import iceCream from "../../assets/menuIcecream.png";
import cafe from "../../assets/menuCafe.png";
import nuggets from "../../assets/menuNuggets.png";
import deals from "../../assets/menuDeals.png";
import fries from "../../assets/menufries.png";
import softdrink from "../../assets/menuSoftDrinks.png";
import wraps from "../../assets/menuWraps.png"
import footerImg from "../../assets/footerLogo.png";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const SelectMenuItemComponent = () => {
  const menuItems = [
    {
      id: 1,
      img: burger,
      name: "Burgers",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 2,
      img: iceCream,
      name: "Ice Cream",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 3,
      img: cafe,
      name: "Coffee",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 4,
      img: nuggets,
      name: "Nuggets",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 5,
      img: deals,
      name: "Deals",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 6,
      img: fries,
      name: "Fries",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
      id: 7,
      img: softdrink,
      name: "Soft Drinks",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    },
    {
        id: 8,
      img: wraps,
      name: "Wraps",
      info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    }
  ];
  const [selectedItem,setSelectedItem]=useState({})

  return (
    <Box
    sx={{
      backgroundColor: "lightgrey",
      minHeight: "100vh", // Full viewport height
      display: "flex",
      justifyContent: "center",
      flexDirection:'column',
      alignItems: "center",
      px: 2, // Padding for small screens
      py:2
    }}
  >
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))", // Fully responsive grid
        gap: '1.5rem', // Reduced gap between items
        justifyContent: "center",
        alignContent: "center", // Center vertically and horizontally
        maxWidth: "1200px", // Constrain content width on larger screens
        width: "100%", // Make grid responsive to container
        mt:5
      }}
    >
      {menuItems.map((item) => (
        <Box
          key={item.id}
          sx={{
            backgroundColor:selectedItem?.id == item?.id  ? "#F37120" :"#fff",
            borderRadius: "18px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            color: "#000000",
            "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",    
              },
            
          }}
          onClick={() => {
            setSelectedItem(item)
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "0",
              paddingTop: "100%", // Aspect ratio 1:1
              position: "relative",
              borderRadius: "16px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={item.img}
              alt={item.name}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "contain", // Maintain aspect ratio
              }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "700",
              mt: 1,
              color:selectedItem?.id == item?.id  ? "#fff" : "#00000"

            }}
          >
            {item.name}
          </Typography>
          <Typography
            sx={{
              color: selectedItem?.id == item?.id  ? "#fff" : "#969BA0",
              fontSize: "12px",
              lineHeight: "1.4rem",
              textAlign: "center",
              mt: 1,
             
            
            }}
          >
            {item.info}
          </Typography>
        </Box>
      ))}
    </Box>
    <Grid2 container mt={4} sx={{width:"100%"}}> 
        <Grid2 size={{md:3}}>
        <Box
              sx={{
                flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
        </Grid2>
        <Grid2 size={{md:6}}>
            <Typography sx={{color:"#969BA0"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum eget nisi placerat viverra. Donec non ipsum vel nisi fermentum consectetur. Nulla facilisi. Donec id velit vel eros dictum tempor.
            </Typography>
        </Grid2>
        <Grid2>
        <Button size='large' sx={{backgroundColor:"#F37120",color:"#fff"}} variant="contained" endIcon={<ShoppingCartIcon sx={{color:"#fff"}} />}>
  View Cart
</Button>
        </Grid2>


    </Grid2>
  </Box>
  
  );
};

export default SelectMenuItemComponent;

