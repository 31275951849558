import {
  Box,
  FormControl,
  Grid2,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import {
  checkGuestLimit,
  setFreeTablesData,
  setGuestCount,
  setIsBackToHoreca,
  setReservationGroupDatas,
  setReservationTypeDatas,
  updateReservationGroup,
  updateReservationType,
  updateSelectedDate,
  updateTime,
  updateTotalCount,
} from "../../store/slice/Addguest.slice";
import { useTranslation } from "react-i18next";
import { updateBlockedDates } from "../../store/slice/widgetSetting.slice";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { ClearIcon } from "@mui/x-date-pickers/icons";

const Addguest = ({ isOpen, handleClose, Ref }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    adult,
    baby,
    child,
    kid,
    toddler,
    isGreaterThan10,
    languageWords,
    selectedDate,
    reservType,
    time,
    reservGroup,
    freeTablesData,
    reservationTypeDatas,
    reservationGroupData,
  } = useSelector((store) => store.addGuest);
  const { WidgetSetting, key, widgetConfig, blockedDates } = useSelector(
    (store) => store?.widgetSettings
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateTotalCount(adult + baby + child + toddler + kid));
  }, [adult, baby, child, kid, toddler]);
  useEffect(() => {
    if (adult > 10) {
      dispatch(
        checkGuestLimit({
          guestType: "adult",
          isGreaterThan10: true,
        })
      );
    } else {
      dispatch(
        checkGuestLimit({
          guestType: "adult",
          isGreaterThan10: false,
        })
      );
    }
  }, [adult]);

  useEffect(() => {
    if (child > 10) {
      dispatch(
        checkGuestLimit({
          guestType: "child",
          isGreaterThan10: true,
        })
      );
    } else {
      dispatch(
        checkGuestLimit({
          guestType: "child",
          isGreaterThan10: false,
        })
      );
    }
  }, [child]);
  useEffect(() => {
    if (kid > 10) {
      dispatch(
        checkGuestLimit({
          guestType: "kid",
          isGreaterThan10: true,
        })
      );
    } else {
      dispatch(
        checkGuestLimit({
          guestType: "kid",
          isGreaterThan10: false,
        })
      );
    }
  }, [kid]);
  useEffect(() => {
    if (baby > 10) {
      dispatch(
        checkGuestLimit({
          guestType: "baby",
          isGreaterThan10: true,
        })
      );
    } else {
      dispatch(
        checkGuestLimit({
          guestType: "baby",
          isGreaterThan10: false,
        })
      );
    }
  }, [baby]);
  useEffect(() => {
    if (toddler > 10) {
      dispatch(
        checkGuestLimit({
          guestType: "toddler",
          isGreaterThan10: true,
        })
      );
    } else {
      dispatch(
        checkGuestLimit({
          guestType: "toddler",
          isGreaterThan10: false,
        })
      );
    }
  }, [toddler]);
  const resetReservationData = () => {
    dispatch(setIsBackToHoreca(false));
    if (blockedDates?.length > 0) dispatch(updateBlockedDates([]));
    if (reservationTypeDatas?.length > 0) dispatch(setReservationTypeDatas([]));
    if (reservationGroupData?.length > 0)
      dispatch(setReservationGroupDatas([]));
    if (freeTablesData?.length > 0) dispatch(setFreeTablesData([]));
    if (Object?.keys(reservType)?.length > 0)
      dispatch(updateReservationType({}));
    if (selectedDate?.trim() !== "") dispatch(updateSelectedDate(""));
    if (time?.trim() !== "") dispatch(updateTime(""));
    if (Object?.keys(reservGroup)?.length > 0)
      dispatch(updateReservationGroup({}));
  };
  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {
        widgetConfig?.fields != null ?
        <Box
        sx={{
          backgroundColor: theme?.guest?.bgColor,
          width: "230px",
          height: "321px",
          pt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 3,
            mb: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: theme?.guest?.headingColor,
              fontWeight: "bold",
            }}
          >
            {languageWords?.Guest}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme?.guest?.headingColor,
              fontWeight: "bold",
            }}
          >
            {languageWords?.quantity}
          </Typography>
        </Box>
        <Box>
          {widgetConfig?.fields?.field1Text?.trim() != "" && (
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
              spacing={2} // Adds spacing between Grid items
            >
              <Grid2
                size={{ xs: 6 }}
                sx={{ textAlign: { xs: "left", sm: "center" } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "12px" },
                    color: theme?.guest?.textColor,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {widgetConfig?.fields?.field1Text}
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6 }}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "32px",
                    px: 1,
                    position: "relative",
                  }}
                >
                  {adult > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        left:isSmallScreen ?  -20 : -30,
                        top: "52%",
                        transform: "translateY(-50%)",
                        color: theme?.guest?.textColor,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          setGuestCount({ guestType: "adult", count: 0 })
                        );
                      }}
                    >
                      <ClearIcon sx={{ width: "100%", color: "grey" }} />
                    </IconButton>
                  )}
                  {isGreaterThan10?.adult ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "0.1rem", sm: "0.2rem" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (adult > 1) {
                            resetReservationData();
                            dispatch(
                              setGuestCount({
                                guestType: "adult",
                                count: adult - 1,
                              })
                            );
                          }
                        }}
                      >
                        -
                      </Typography>

                      <TextField
                        sx={{
                          height: "30px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                          },
                        }}
                        size="small"
                        placeholder="00"
                        name="adult"
                        value={adult}
                        disabled={true}
                      />

                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "adult",
                              count: adult + 1,
                            })
                          );
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Select
                        id="demo-simple-select"
                        value={adult}
                        size="small"
                        fullWidth
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: theme?.guest?.textColor,
                            fontSize: { sm: "12px" },
                          },
                        }}
                        onChange={(event) => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "adult",
                              count: Number(event.target.value),
                            })
                          );
                        }}
                      >
                        {new Array(10).fill(null).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            sx={{
                              color: theme?.guest?.dropdownTextColor,
                              fontSize: { sm: "12px" },
                              "&:hover": {
                                backgroundColor: theme?.guest?.dropdownBgHover,
                                color: theme?.guest?.dropdownTextColorHover,
                              },
                            }}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={11}
                          sx={{
                            color: theme?.guest?.dropdownTextColor,
                            fontSize: { sm: "12px" },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              checkGuestLimit({
                                guestType: "adult",
                                isGreaterThan10: true,
                              })
                            );
                            dispatch(
                              setGuestCount({ guestType: "adult", count: 11 })
                            );
                          }}
                        >
                          <AddBoxTwoToneIcon
                            sx={{
                              color: theme?.guest?.plusColor,
                              width: { xs: 16, sm: 20 },
                              height: { xs: 16, sm: 20 },
                              "&:hover": {
                                color: theme?.guest?.plusHover,
                              },
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </Box>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          )}

          {widgetConfig?.fields?.field2Text?.trim() != "" && (
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
              spacing={2} // Adds spacing between Grid items
            >
              <Grid2
                size={{ xs: 6 }}
                sx={{ textAlign: { xs: "left", sm: "center" } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "12px" },
                    color: theme?.guest?.textColor,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {widgetConfig?.fields?.field2Text}
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6 }}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "32px",
                    px: 1,
                    position: "relative",
                  }}
                >
                  {child > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        left:isSmallScreen ?  -20 : -30,
                        top: "52%",
                        transform: "translateY(-50%)",
                        color: theme?.guest?.textColor,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          setGuestCount({ guestType: "child", count: 0 })
                        );
                      }}
                    >
                      <ClearIcon sx={{ width: "100%", color: "grey" }} />
                    </IconButton>
                  )}
                  {isGreaterThan10?.child ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "0.1rem", sm: "0.2rem" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (child > 1) {
                            resetReservationData();
                            dispatch(
                              setGuestCount({
                                guestType: "child",
                                count: child - 1,
                              })
                            );
                          }
                        }}
                      >
                        -
                      </Typography>

                      <TextField
                        sx={{
                          height: "30px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                          },
                        }}
                        size="small"
                        placeholder="00"
                        name="child"
                        value={child}
                        disabled={true}
                      />

                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "child",
                              count: child + 1,
                            })
                          );
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Select
                        id="demo-simple-select"
                        value={child}
                        size="small"
                        fullWidth
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: theme?.guest?.textColor,
                            fontSize: { sm: "12px" },
                          },
                        }}
                        onChange={(event) => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "child",
                              count: Number(event.target.value),
                            })
                          );
                        }}
                      >
                        {new Array(10).fill(null).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            sx={{
                              color: theme?.guest?.dropdownTextColor,
                              fontSize: { sm: "12px" },
                              "&:hover": {
                                backgroundColor: theme?.guest?.dropdownBgHover,
                                color: theme?.guest?.dropdownTextColorHover,
                              },
                            }}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={11}
                          sx={{
                            color: theme?.guest?.dropdownTextColor,
                            fontSize: { sm: "12px" },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              checkGuestLimit({
                                guestType: "child",
                                isGreaterThan10: true,
                              })
                            );
                            dispatch(
                              setGuestCount({ guestType: "child", count: 11 })
                            );
                          }}
                        >
                          <AddBoxTwoToneIcon
                            sx={{
                              color: theme?.guest?.plusColor,
                              width: { xs: 16, sm: 20 },
                              height: { xs: 16, sm: 20 },
                              "&:hover": {
                                color: theme?.guest?.plusHover,
                              },
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </Box>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          )}
             {widgetConfig?.fields?.field3Text?.trim() != "" && (
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
              spacing={2} // Adds spacing between Grid items
            >
              <Grid2
                size={{ xs: 6 }}
                sx={{ textAlign: { xs: "left", sm: "center" } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "12px" },
                    color: theme?.guest?.textColor,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {widgetConfig?.fields?.field3Text}
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6 }}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "32px",
                    px: 1,
                    position: "relative",
                  }}
                >
                  {kid > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        left: isSmallScreen ?  -20 : -30,
                        top: "52%",
                        transform: "translateY(-50%)",
                        color: theme?.guest?.textColor,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          setGuestCount({ guestType: "kid", count: 0 })
                        );
                      }}
                    >
                      <ClearIcon sx={{ width: "100%", color: "grey" }} />
                    </IconButton>
                  )}
                  {isGreaterThan10?.kid ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "0.1rem", sm: "0.2rem" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (kid > 1) {
                            resetReservationData();
                            dispatch(
                              setGuestCount({
                                guestType: "kid",
                                count: kid - 1,
                              })
                            );
                          }
                        }}
                      >
                        -
                      </Typography>

                      <TextField
                        sx={{
                          height: "30px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                          },
                        }}
                        size="small"
                        placeholder="00"
                        name="kid"
                        value={kid}
                        disabled={true}
                      />

                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "kid",
                              count: kid + 1,
                            })
                          );
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Select
                        id="demo-simple-select"
                        value={kid}
                        size="small"
                        fullWidth
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: theme?.guest?.textColor,
                            fontSize: { sm: "12px" },
                          },
                        }}
                        onChange={(event) => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "kid",
                              count: Number(event.target.value),
                            })
                          );
                        }}
                      >
                        {new Array(10).fill(null).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            sx={{
                              color: theme?.guest?.dropdownTextColor,
                              fontSize: { sm: "12px" },
                              "&:hover": {
                                backgroundColor: theme?.guest?.dropdownBgHover,
                                color: theme?.guest?.dropdownTextColorHover,
                              },
                            }}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={11}
                          sx={{
                            color: theme?.guest?.dropdownTextColor,
                            fontSize: { sm: "12px" },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              checkGuestLimit({
                                guestType: "kid",
                                isGreaterThan10: true,
                              })
                            );
                            dispatch(
                              setGuestCount({ guestType: "kid", count: 11 })
                            );
                          }}
                        >
                          <AddBoxTwoToneIcon
                            sx={{
                              color: theme?.guest?.plusColor,
                              width: { xs: 16, sm: 20 },
                              height: { xs: 16, sm: 20 },
                              "&:hover": {
                                color: theme?.guest?.plusHover,
                              },
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </Box>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          )}


        
          {widgetConfig?.fields?.field4Text?.trim() != "" && (
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
              spacing={2} // Adds spacing between Grid items
            >
              <Grid2
                size={{ xs: 6 }}
                sx={{ textAlign: { xs: "left", sm: "center" } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "12px" },
                    color: theme?.guest?.textColor,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {widgetConfig?.fields?.field4Text}
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6 }}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "32px",
                    px: 1,
                    position: "relative",
                  }}
                >
                  {baby > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        left: isSmallScreen ?  -20 : -30,
                        top: "52%",
                        transform: "translateY(-50%)",
                        color: theme?.guest?.textColor,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          setGuestCount({ guestType: "baby", count: 0 })
                        );
                      }}
                    >
                      <ClearIcon sx={{ width: "100%", color: "grey" }} />
                    </IconButton>
                  )}
                  {isGreaterThan10?.baby ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "0.1rem", sm: "0.2rem" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (kid > 1) {
                            resetReservationData();
                            dispatch(
                              setGuestCount({
                                guestType: "baby",
                                count: baby - 1,
                              })
                            );
                          }
                        }}
                      >
                        -
                      </Typography>

                      <TextField
                        sx={{
                          height: "30px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                          },
                        }}
                        size="small"
                        placeholder="00"
                        name="baby"
                        value={baby}
                        disabled={true}
                      />

                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "baby",
                              count: baby + 1,
                            })
                          );
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Select
                        id="demo-simple-select"
                        value={baby}
                        size="small"
                        fullWidth
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: theme?.guest?.textColor,
                            fontSize: { sm: "12px" },
                          },
                        }}
                        onChange={(event) => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "baby",
                              count: Number(event.target.value),
                            })
                          );
                        }}
                      >
                        {new Array(10).fill(null).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            sx={{
                              color: theme?.guest?.dropdownTextColor,
                              fontSize: { sm: "12px" },
                              "&:hover": {
                                backgroundColor: theme?.guest?.dropdownBgHover,
                                color: theme?.guest?.dropdownTextColorHover,
                              },
                            }}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={11}
                          sx={{
                            color: theme?.guest?.dropdownTextColor,
                            fontSize: { sm: "12px" },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              checkGuestLimit({
                                guestType: "baby",
                                isGreaterThan10: true,
                              })
                            );
                            dispatch(
                              setGuestCount({ guestType: "baby", count: 11 })
                            );
                          }}
                        >
                          <AddBoxTwoToneIcon
                            sx={{
                              color: theme?.guest?.plusColor,
                              width: { xs: 16, sm: 20 },
                              height: { xs: 16, sm: 20 },
                              "&:hover": {
                                color: theme?.guest?.plusHover,
                              },
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </Box>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          )}
          {widgetConfig?.fields?.field5Text?.trim() != "" && (
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
              spacing={2} // Adds spacing between Grid items
            >
              <Grid2
                size={{ xs: 6 }}
                sx={{ textAlign: { xs: "left", sm: "center" } }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: "12px" },
                    color: theme?.guest?.textColor,
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  {widgetConfig?.fields?.field5Text}
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6 }}>
                <FormControl
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "32px",
                    px: 1,
                    position: "relative",
                  }}
                >
                  {toddler > 0 && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        left:isSmallScreen ?  -20 : -30,
                        top: "52%",
                        transform: "translateY(-50%)",
                        color: theme?.guest?.textColor,
                        width: "30px",
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        dispatch(
                          setGuestCount({ guestType: "toddler", count: 0 })
                        );
                      }}
                    >
                      <ClearIcon sx={{ width: "100%", color: "grey" }} />
                    </IconButton>
                  )}
                  {isGreaterThan10?.toddler ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: "0.1rem", sm: "0.2rem" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (toddler > 1) {
                            resetReservationData();
                            dispatch(
                              setGuestCount({
                                guestType: "toddler",
                                count: toddler - 1,
                              })
                            );
                          }
                        }}
                      >
                        -
                      </Typography>

                      <TextField
                        sx={{
                          height: "30px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: theme?.guest?.dropdownBorderColor,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                          },
                        }}
                        size="small"
                        placeholder="00"
                        name="toddler"
                        value={toddler}
                        disabled={true}
                      />

                      <Typography
                        sx={{
                          fontSize: { xs: "18px", sm: "24px" },
                          color: theme?.guest?.textColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "toddler",
                              count: toddler + 1,
                            })
                          );
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Select
                        id="demo-simple-select"
                        value={toddler}
                        size="small"
                        fullWidth
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.guest?.dropdownBorderColor,
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: theme?.guest?.textColor,
                            fontSize: { sm: "12px" },
                          },
                        }}
                        onChange={(event) => {
                          resetReservationData();
                          dispatch(
                            setGuestCount({
                              guestType: "toddler",
                              count: Number(event.target.value),
                            })
                          );
                        }}
                      >
                        {new Array(10).fill(null).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            sx={{
                              color: theme?.guest?.dropdownTextColor,
                              fontSize: { sm: "12px" },
                              "&:hover": {
                                backgroundColor: theme?.guest?.dropdownBgHover,
                                color: theme?.guest?.dropdownTextColorHover,
                              },
                            }}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={11}
                          sx={{
                            color: theme?.guest?.dropdownTextColor,
                            fontSize: { sm: "12px" },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              checkGuestLimit({
                                guestType: "toddler",
                                isGreaterThan10: true,
                              })
                            );
                            dispatch(
                              setGuestCount({ guestType: "toddler", count: 11 })
                            );
                          }}
                        >
                          <AddBoxTwoToneIcon
                            sx={{
                              color: theme?.guest?.plusColor,
                              width: { xs: 16, sm: 20 },
                              height: { xs: 16, sm: 20 },
                              "&:hover": {
                                color: theme?.guest?.plusHover,
                              },
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </Box>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          )}
        </Box>
      </Box>
      :
      <Box>
        <Box
          sx={{
            backgroundColor: theme?.guest?.bgColor,
            width: "230px",
            height: "321px",
            pt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "18px" },
             
            }}
          >
           No fields found
          </Typography>
        </Box>
    
      </Box>
      }
     
    </Popover>
  );
};

export default Addguest;
