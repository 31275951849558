import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
    setExtraFieldsData,
    setGuestDetailsData,
    setIsBackToHoreca,
    setTransactionDetails,
    toggleBookNowOpen,
    updateDefaultLanguage,
} from "../../store/slice/Addguest.slice";
import {
    CircularProgress,
    Paper,
    StepConnector,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import YourDetails from "./YourDetails";
import Extra from "./Extra";
import Combination from "./Combination";
import ConfirmandPay from "./ConfirmandPay";
import backicon from "../../assets/backwidget.png";
import nexticon from "../../assets/next.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import HorecaWidget from "./HorecaWidget";

import {
    createReservation,
    getExtraFieldDetails,
    getGuestDetails,
    transactionCostForReservation,
} from "../../api/HorecaWidget.service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
dayjs?.extend(customParseFormat); // Extend for custom date format parsing

const DottedStepConnector = (props) => (
    <StepConnector
        {...props}
        sx={{
            "& .MuiStepConnector-line": {
                borderColor: "#000000", // Line color
                borderStyle: "dashed", // Dashed line style (simulates dotted with spacing)
                borderWidth: 1, // Line thickness
            },
        }}
    />
);

export default function BookNowForm() {
    const {
        widgetConfig,
        reservType,
        GuestDetailsData,
        extraProductData,
        reservGroup,
        adult,
        child,
        kid,
        baby,
        toddler,
        selectedDate,
        time,
        totalCount,
        selectedExtraProduct,
        transactionDetails,
        guestFields,
        addFields,
        reservationLang,
        combinationWidgetData,
        languageWords,
        defaultLanguage,
        extraFieldsData,
    } = useSelector((store) => store.addGuest);
    const [steps, setSteps] = React.useState([
        languageWords?.Availability,
        languageWords?.ExtraProducts,
        languageWords?.combinations,
        languageWords?.yourDetails,
        languageWords?.ConfirmandPay,
    ]);
    const [isLoading, setIsLoading] = React.useState();
    const theme = useTheme();
    const {
        WidgetSetting,
        key,
        floating,
        widgetSetupId,
        buttonPosition,
        widgetPosition,
        resType,
    } = useSelector((store) => store?.widgetSettings);
    const reservationRequestMin =
        parseInt(
            WidgetSetting?.find((item) => item?.tag === "reservationRequest")
        ) ?? 0;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(1);
    const [completed, setCompleted] = React.useState({ 0: false });
    const { isBookNowOpen } = useSelector((store) => store.addGuest);
    const dispatch = useDispatch();
    const totalSteps = () => steps.length;
    const [state, setState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, open } = state;
    const handleClosebar = () => {
        setState({ ...state, open: true });

        setTimeout(() => {
            setState({ ...state, open: false });
        }, 5000);
    };
    const completedSteps = () => Object.keys(completed).length;
    const isLastStep = () => activeStep === totalSteps() - 1;
    const allStepsCompleted = () => completedSteps() === totalSteps();
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleBack = () => {
        if (activeStep == 1) {
            dispatch(setIsBackToHoreca(true));
            navigate(-1); 
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        setCompleted({
            ...completed,
            [activeStep]: true,
        });
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    // Modal open/close functions
    const handleClickOpen = () => {
        dispatch(toggleBookNowOpen());
    };

    const handleClose = () => {
        dispatch(toggleBookNowOpen());
    };

    const fetchGuestDetails = async () => {
        try {
            const response = await getGuestDetails(
                key,
                reservType?.idreservationtype,
                defaultLanguage
            );
            if(response?.status != 200 || !response?.data){
                console.error("Failed to fetch guest details:", response);
                return;
            }
            dispatch(setGuestDetailsData(response?.data));
        } catch (error) {
            console.error(error);
        }
    };

    const fetchExtraFieldDetails = async () => {
        try {
            const response = await getExtraFieldDetails(
                reservType?.idreservationtype,
                defaultLanguage,
                key
            );
            dispatch(setExtraFieldsData(response));
        } catch (error) {
            console.error(error);
        }
    };

    const creatBookingReservation = async () => {
        setIsLoading(true);
        const parsedDate = dayjs(selectedDate, "DD-MM-YYYY", true);
        // Check if date is valid
        if (!parsedDate?.isValid()) {
            console.error("Invalid date:", selectedDate);
            return;
        }
        // Format the valid date into 'YYYY-MM-DD'
        const formattedDate = parsedDate.format("YYYY-MM-DD");

        const upsaleproduct = selectedExtraProduct?.map((item) => {
            return {
                idupsaleproduct: item?.idproduct,
                quantity: item?.quantity,
                price: item.price * item?.quantity,
                supportingproducts: null,
            };
        });

        const payload = {
            idreservationtype: reservType?.idreservationtype,
            idarrangementtype: reservGroup?.idarrangementtype,
            field1: parseInt(adult),
            field2: parseInt(child),
            field3: parseInt(baby),
            field4: parseInt(toddler),
            field5: parseInt(kid),
            arrivaldate: formattedDate,
            arrivaltime: `${time};${reservType?.duration};${reservType.durationgroups}`,
            key: key,
            language: defaultLanguage,
            newsletter: false,
            reservationRequest: false,
            guestFields: guestFields || [],
            fields: addFields,
            app: false,
            horecaProductReservation: {
                idhorecaproduct: reservType?.idproducthoreca ?? 0,
                numberofguests: totalCount,
                price: resType?.totalCost * totalCount ?? 0,
            },
            upsaleProductReservations: upsaleproduct || [],
            transactionCostReservation: {
                idproduct: transactionDetails?.idproduct,
                idreservation: reservType?.idreservationtype,
                price: transactionDetails?.pricePerReservation,
            },
            baseUrl: "",
            isGroupReservation: false,
            reservationRequestMin: reservationRequestMin?.data,
            MainModuleId: 1,
        };
        try {
            const response = await createReservation(payload);

            if (response?.status == 200) {
                window.parent.postMessage(
                    {
                        redirectUrl: `${response?.data?._links.checkout.href}`,
                    },
                    "*"
                );
                //window.location = response._links.checkout.href;
                //    setState({
                //      open: true,
                //vertical: 'top',
                //horizontal: 'center',
                //    });
                //  const secretkey = "a22a97cd-a569-4978-a545-0658e35bc48e";
                //  const key = "236b0fdb-e3c3-4fa2-b0a6-9628936ffc6e";


                // navigate(
                //   `/?secretkey=${encodeURIComponent(
                //     secretkey
                //   )}&key=${encodeURIComponent(key)}`
                // );
            } else {
                handleClosebar()
                // navigate(`/?key=${key}`);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const fetchTransactionDetails = async () => {
        try {
            const response = await transactionCostForReservation(
                key,
                reservType?.idreservationtype,
                defaultLanguage
            );
            if(response?.status !=200 || !response?.data){
                throw new Error('Transaction details not found')
            }
            dispatch(setTransactionDetails(response?.data));
        } catch (error) {
            console.log("error in transaction cost:", error?.message);
            throw new Error(error?.message);
        }
    };

    const createInitialValuesAndValidationSchema = (guestData, extraData) => {
        const initialValues = {};
        const validationShape = {};

        // Helper function to process each field item and add to initialValues and validationShape
        const processFieldData = (field, languageType, cusomLink) => {
            let languageData = field[languageType]?.[0]?.data;
            if (cusomLink) {
                languageData = "Link";
            }
            else if (field?.type == 'textarea') {
                if(typeof field[languageType]?.[0]?.data === 'string'){
                    languageData = field[languageType]?.[0]?.data?.replace(".", "")   
                }
                else{
                    languageData = field[languageType]?.[0]?.data
                }
               
                initialValues[languageData] = ''
            }
            if (field?.type == "checkboxList" || field?.type == "radio") {
                initialValues[languageData] = [];
            } else {
                initialValues[languageData] = ""; // Initialize field values as empty strings
            }

            // Set validation schema based on field requirements and tag type
            if (field.required) {
                if (field.tag === "{email}") {
                    validationShape[languageData] = Yup.string()
                        .email("Invalid email format")
                        .required("Required");
                } else if (field.tag === "{phone}") {
                    validationShape[languageData] = Yup.string()
                        .matches(
                            /^\+?\d{1,3}\s*\(?\d{1,4}?\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/,
                            "Invalid phone number format"
                        )
                        .required("Required");
                } else {
                    if (field?.type == "checkboxList") {
                        validationShape[languageData] = Yup.array().of(Yup.string());
                    } else {
                        validationShape[languageData] = Yup.string();
                    }
                }
            } else {
                validationShape[languageData] =
                    field.tag === "{email}"
                        ? Yup.string().email("Invalid email format")
                        : field.tag === "{phone}"
                            ? Yup.string().matches(
                                /^\+?\d{1,3}\s*\(?\d{1,4}?\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/,
                                "Invalid phone number format"
                            )
                            : field?.type == "checkboxList"
                                ? Yup.array().of(Yup.string())
                                : Yup.string();
            }
        };

        // Process guestData with guestLanguages
        guestData?.forEach((field) =>
            processFieldData(
                field,
                "guestLanguages",
                field.type == "link" ? true : false
            )
        );

        // Process extraData with fieldsLanguages
        extraData?.forEach((field) =>
            processFieldData(
                field,
                "fieldsLanguages",
                field.type == "link" ? true : false
            )
        );

        // Return both initialValues and validationSchema
        return {
            initialValues,
            validationSchema: Yup.object().shape(validationShape),
        };
    };
    const { initialValues, validationSchema } =
        createInitialValuesAndValidationSchema(GuestDetailsData, extraFieldsData);
   

    React.useEffect(() => {
        fetchGuestDetails();
        fetchTransactionDetails();
        fetchExtraFieldDetails();
    }, []);



    // create a React.userEffect that listens to the dom changes and passes a console.log of the number of changes in the dom
    const send = (
        frameHeight,
        frameWidth,
        top,
        right,
        bottom,
        left,
        floating,
        redirectUrl
    ) => {
        window.parent.postMessage(
            {
                reservationIframeHeight: frameHeight,
                reservationIframeWidth: frameWidth,
                reservationIframePositionTop: top,
                reservationIframePositionRight: right,
                reservationIframePositionBottom: bottom,
                reservationIframePositionLeft: left,
                reservationIframePositionFloating: floating,
                redirectUrl: redirectUrl,
            },
            "*"
        );
    };
    const getHeight = () => {
        const height = setTimeout(() => {
            const frameHeight = document.getElementsByClassName("MuiBox-root")[1].clientHeight + 50;
            send(
                frameHeight + "px",
                "100%",
                "",
                "0px",
                "0px",
                "",
                widgetConfig?.settings?.floatButtonActive
            )
        }, 100);
    };

    React.useEffect(() => {
        const targetNode = document.body;
        const config = { attributes: true, childList: true, subtree: true };



        const callback = (mutationsList) => {
            if (floating == "relative") {
                getHeight();
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);

        return () => {
            observer.disconnect();
        };
    }, []);



    React.useEffect(() => {

        if (extraProductData?.length == 0 && combinationWidgetData == null) {
            setSteps([
                languageWords?.Availability,
                languageWords?.yourDetails,
                languageWords?.ConfirmandPay,
            ]);
        } else if (extraProductData?.length == 0 && combinationWidgetData != null) {
            setSteps([
                languageWords?.Availability,
                languageWords?.combinations,
                languageWords?.yourDetails,
                languageWords?.ConfirmandPay,
            ]);
        }
    }, [extraProductData]);

    React.useEffect(() => {
        document.body.setAttribute('style', 'background-color: ' + theme?.widget?.stepScreenBgColor + ' !important'); // theme?.widget?.stepScreenBgColor;
         if (combinationWidgetData == null && extraProductData?.length == 0) {
            setSteps([
                languageWords?.Availability,
                languageWords?.yourDetails,
                languageWords?.ConfirmandPay,
            ]);
        } else if (combinationWidgetData == null && extraProductData?.length != 0) {
            setSteps([
                languageWords?.Availability,
                languageWords?.ExtraProducts,
                languageWords?.yourDetails,
                languageWords?.ConfirmandPay,
            ]);
        }
    }, [combinationWidgetData]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // Ensure that form is valid before calling creatBookingReservation
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    touched,
                    errors,
                    dirty,
                    setFieldTouched,
                    setFieldValue,
                }) => (
                    <Box
                        sx={{
                            backgroundColor: theme?.widget?.stepScreenBgColor,
                            height: '100vh',
                            p: 1
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: theme?.widget?.stepScreenBgColor
                            }}
                        >

                            <Box
                                sx={{
                                    width: "100%", // Take full width
                                    overflow: "hidden", // Prevent overflow
                                    px: 2,
                                    mb: 5,
                                }}
                            >
                                <Stepper
                                    nonLinear
                                    activeStep={activeStep}
                                    connector={<DottedStepConnector />}
                                    sx={{
                                        mt: 1,
                                        flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap steps on small screens
                                        justifyContent: { xs: "center", sm: "flex-start" }, // Center on xs and align to start on sm+
                                    }}
                                >
                                    {steps.map((label, index) => (
                                        <Step key={label} completed={completed[index]}>
                                            <StepButton
                                                sx={{
                                                    ...(activeStep === index && {
                                                        "& .MuiStepLabel-label": {
                                                            color:
                                                                activeStep === index
                                                                    ? theme?.widget?.stepTextColorActive
                                                                    : theme?.widget?.stepTextColor,
                                                            fontWeight: "bold",
                                                            fontSize: "15px",
                                                        },
                                                        "& .MuiStepIcon-root": {
                                                            color:
                                                                activeStep === index
                                                                    ? theme?.widget?.stepCircleBgColor
                                                                    : theme?.widget?.stepCircleActiveBg,
                                                            borderRadius: "50%",
                                                            // Adding the following line for custom icon text color when active
                                                            "& .MuiStepIcon-text": {
                                                                fill:
                                                                    activeStep === index
                                                                        ? theme?.widget?.stepCircleTextActive
                                                                        : theme?.widget?.stepCircleTextColor,
                                                            },
                                                        },
                                                    }),
                                                    width: { xs: "100%", sm: "auto" },
                                                    fontSize: { xs: "12px", sm: "inherit" },
                                                }}
                                                // onClick={handleStep(index)}
                                                disabled={true}
                                            >
                                                {isSmallScreen ? "" : label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <div>
                                {allStepsCompleted() ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            All steps completed - you're finished
                                        </Typography>
                                        <Box
                                            sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                        >
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                        <React.Fragment>
                                            <Box>
                                            {(() => {
                                                switch (steps[activeStep]) {
                                                    case languageWords?.Availability:
                                                        navigate(-1, {
                                                            state: { openWidgetScreen: true },
                                                        });
                                                        break;

                                                    case languageWords?.ExtraProducts:
                                                        return <Extra />;

                                                    case languageWords?.combinations:
                                                        return <Combination />;

                                                    case languageWords?.yourDetails:
                                                        return (
                                                            <YourDetails
                                                                values={values}
                                                                handleChange={handleChange}
                                                                setField={setFieldValue}
                                                                errors={errors}
                                                                touched={touched}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                        );

                                                    case languageWords?.ConfirmandPay || "Confirm":
                                                        return <ConfirmandPay />;

                                                    default:
                                                        return <div>No page found</div>;
                                                }
                                            })()}
                                        </Box>

                                        <Box
                                            sx={{ display: "flex", flexDirection: "row", mt: 1, pb: 10 }}
                                        >
                                            <Button
                                                variant="contained"
                                                //  disabled={activeStep === 1}
                                                onClick={handleBack}
                                                sx={{
                                                    mr: 1,
                                                    backgroundColor: theme?.details?.backButtonBgColor,
                                                    color: theme?.details?.backButtonTextColor,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme?.details?.backButtonBgColorHover,
                                                        color: theme?.details?.backButtonTextColorHover,
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img src={backicon} alt="back-img" />
                                                    {languageWords?.Previous}
                                                </Box>
                                            </Button>
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            {activeStep !== 4 && (
                                                <Button
                                                    onClick={() => {
                                                        if (
                                                            steps[activeStep] === languageWords?.yourDetails
                                                        ) {
                                                            if (Object.keys(errors).length == 0) {
                                                                creatBookingReservation();
                                                            }

                                                            // Submit the form when on step 3
                                                        } else {
                                                            handleNext(); // Proceed to the next step otherwise
                                                        }
                                                    }}
                                                    type={
                                                        steps[activeStep] === languageWords?.yourDetails
                                                            ? "submit"
                                                            : "button"
                                                    }
                                                    sx={{
                                                        mr: 1,

                                                        backgroundColor:
                                                            steps[activeStep] === languageWords?.yourDetails
                                                                ? reservType?.totalCost > 0
                                                                    ? theme?.details?.confirmBgColor
                                                                    : theme?.details?.nextButtonBgColor
                                                                : theme?.details?.nextButtonBgColor,
                                                        // color:theme?.details?.nextButtonTextColor,
                                                        color:
                                                            steps[activeStep] === languageWords?.yourDetails
                                                                ? reservType?.totalCost > 0
                                                                    ? theme?.details?.confirmTextColor
                                                                    : theme?.details?.nextButtonTextColor
                                                                : theme?.details?.nextButtonTextColor,
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme?.details?.nextButtonBgColorHover,
                                                            color: theme?.details?.nextButtonTextColorHover,
                                                        },
                                                    }}
                                                    disabled={
                                                        Object.keys(errors).length != 0 &&
                                                        steps[activeStep] === languageWords?.yourDetails
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img src={nexticon} alt="next-img" />
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                size="20px"
                                                                sx={{ color: "#fff" }}
                                                            />
                                                        ) : steps[activeStep] ===
                                                            languageWords?.yourDetails ? (
                                                            reservType?.totalCost > 0 ? (
                                                                languageWords?.ConfirmandPay
                                                            ) : (
                                                                languageWords?.Confirm
                                                            )
                                                        ) : (
                                                            languageWords?.Next
                                                        )}
                                                    </Box>
                                                </Button>
                                            )}
                                        </Box>
                                    </React.Fragment>
                                )}
                            </div>
                            {/* </Paper> */}
                        </Box>
                    </Box>
                )}
            </Formik>
            <Box sx={{ width: 500 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClosebar}
                    message="Reservation creation Unsuccessful"
                    key={vertical + horizontal}
                />
            </Box>
        </>
    );
}
