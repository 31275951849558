import { Box, Popover, Typography, Skeleton, useTheme, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCombinationWidgetData, setFreeTablesData, setIsBackToHoreca, updateReservationGroup, updateTime } from "../../store/slice/Addguest.slice";
import { fetchCombinationWidget, getFreeTables } from "../../api/HorecaWidget.service";
import dayjs from "dayjs";



const TimeComponent = ({ isOpen, handleClose, Ref }) => {
  const theme=useTheme()
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { reservType, selectedDate, totalCount, time,freeTablesData,reservGroup,isBackToHoreca,languageWords} = useSelector((store) => store?.addGuest);

  const { WidgetSetting, key,defaultLanguage } = useSelector((store) => store?.widgetSettings);
  const widgetMinTimeBeforeArrival = WidgetSetting?.find((item) => item?.tag == 'widgetMinTimeBeforeArrival');
  const ReservationReqMin = WidgetSetting?.find((item) => item?.tag == 'reservationRequest') ;
  const [selectedTime, setSelectedTime] = useState('');
 

const getTimeDetails = async () => {
    setIsLoading(true);
    const parsedDate = dayjs(selectedDate, 'DD-MM-YYYY');
    const formattedDate = parsedDate?.format('YYYY-MM-DD');
    const payload = {
      idreservationtype: parseInt(reservType.idreservationtype) ?? 0,
      duration: parseInt(reservType?.duration) ?? 0,
      durationgroups: parseInt(reservType?.durationgroups) ?? 0,
      bookableupto: (reservType && reservType?.bookableupto?.HasValue && reservType?.bookableupto > 0) ? parseInt(reservType?.bookableupto.Value) : parseInt(reservType.duration) ?? 0,
      date: formattedDate,
      totalpersons: parseInt(totalCount),
      key: key,
      addTime: parseInt(widgetMinTimeBeforeArrival?.data),
      lang: defaultLanguage,
    //  reservationRequestMin: !(reservType && reservType?.bookableupto?.HasValue && reservType?.bookableupto > 0) ? parseInt(ReservationReqMin?.data)  : 0
    reservationRequestMin:22
    };

    try {
      const response = await getFreeTables(payload);
      if(response?.status !=200 || !response?.data){
        throw new Error('No free tables found');
      }
      dispatch(setFreeTablesData(response?.data));
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching get time details:", error?.message);
      setIsLoading(false);
    }
  };

  const fetchCombinationWidgetDetails = async () => {
    const starttime = time
    const secondsToAdd = reservType.duration; // Seconds to add (1 hour)
    // Create a Day.js object for the arrival time
    const times = dayjs(`2024-01-01T${starttime}`); // Use a dummy date
    // Add seconds to the arrival time
    const departureTime = times.add(secondsToAdd, 'second').format('HH:mm:ss');
    try {
        const response = await fetchCombinationWidget
        (
            parseInt(reservType.idreservationtype),
            key,
            dayjs(selectedDate, 'DD-MM-YYYY', true),
            time,
            departureTime,
            parseInt(totalCount),
            defaultLanguage || 'NL'
            )
          if(response?.status !=200 || !response?.data){
            throw new Error('No combination widget found ');
          }
          dispatch(setCombinationWidgetData(response?.data?.data))
    } catch (error) {
        console.error("Error fetching combination widget", error?.message);
        throw new Error(error?.message)
    }
}
useEffect(() => {
   if(time.trim() != '' && !isBackToHoreca){
    fetchCombinationWidgetDetails()
   }
}, [time,isBackToHoreca])

  useEffect(() => {
    if (Object.keys(reservType).length > 0 &&  !isBackToHoreca) {
      getTimeDetails();
    }
  }, [reservType,isBackToHoreca]);



  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
          <Box sx={{ width: "484px", backgroundColor: theme?.time?.bgColor, p: 2 }} className="times">
        <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', my: 2 }}>
          <Typography sx={{ color: theme?.time?.itemTextColor, fontSize: "14px", fontWeight: 'bold' }}>{languageWords?.labelSelectTime}</Typography>
        </Box>

        <Box sx={{
          display: "flex",
        //  justifyContent: "ce",
          alignItems: "center",
          flexWrap: 'wrap',
          gap: "1rem",
        }}>
          {isLoading ? (
            // Render Skeletons while loading
            Array.from(new Array(5))?.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: "137px",
                  height: '51px',
                  border: "1px solid #C9C9C9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <Skeleton variant="text" width={80} height={30} />
              </Box>
            ))
          ) : (
            // Render actual time options
            freeTablesData?.map((item, index) => (
              <Tooltip
              key={index}
              title={item?.locked ? item?.lockedAlert : ""} // Show tooltip only if `locked`
              arrow
              disableHoverListener={!item?.locked} // Enable tooltip hover only if `locked` is true
              >
                <Box
                  sx={{
                    width: "137px",
                    height: "51px",
                    border: "1px solid",
                    borderColor:  theme?.time?.itemBorder,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: item.locked ? "not-allowed" : "pointer",
                    transition: "background-color 0.3s ease",
                    borderRadius: "5px",
                    backgroundColor: time === item?.time
                      ? theme?.time?.itemBGActive
                      : theme.time.itemBG,
                    color: time === item.time
                      ? theme?.time?.itemTextActive
                      : theme?.time?.itemTextColor,
                    "&:hover": {
                      backgroundColor: theme?.time?.itemBgHover,
                      "& .text": {
                        color: item?.locked ? theme?.time?.itemTextColor :theme?.time?.itemTextHover,
                      },
                    },
                    textDecoration: item?.locked ? "line-through" : "none", // Strikethrough if locked
                    opacity: item?.locked ? 0.5 : 1, // Optional: dim locked items
                  }}
                  onClick={() => {
                    if (!item?.locked) { // Prevent click if locked
                      if(Object?.keys(reservGroup)?.length > 0){
                        dispatch(updateReservationGroup({}));    
                      } 
                      dispatch(setIsBackToHoreca(false))                 
                      dispatch(updateTime(item.time));
                      handleClose();
                    }
                  }}
                >
                  <Typography
                    className="text"
                  >
                    {item?.time.substring(0, 5)}
                  </Typography>
                </Box>
              </Tooltip>
            ))
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default TimeComponent;

