import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import widgetSettingSlice from "../../store/slice/widgetSetting.slice";
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const YourBookingCard = ({bowling={}}) => {
  const theme=useTheme()
  dayjs.extend(advancedFormat);
  const {totalCount,selectedDate,reservType,time,reservGroup,adult,child,kid,baby,toddler,reservationGroupData,selectedExtraProduct,languageWords
    } = useSelector((store) => store.addGuest); 
  const totalSum = selectedExtraProduct.reduce((acc, product) => {
      return acc + (product.quantity * product.price);
  }, 0);
  const {
        WidgetSetting,
        key,
        widgetConfig
    } = useSelector((store) => store?.widgetSettings);
  
  const originalDate = dayjs(selectedDate, 'DD-MM-YYYY');
    const formatDateWithLanguage = (originalDate) => {
      const dayName = originalDate.format('dddd');  // Get the day name (e.g., "Thursday")
      const dayNumber = originalDate.format('DD');  // Get the day number (e.g., "11")
      const monthName = originalDate.format('MMMM');  // Get the month name (e.g., "November")
      const year = originalDate.format('YYYY');  // Get the year (e.g., "2024")
    
      // Translate day and month names using `languageWords`
      const translatedDayName = languageWords[dayName] || dayName;
      const translatedMonthName = languageWords[monthName] || monthName;
    
      // Construct the translated date string
      return `${translatedDayName} ${dayNumber} ${translatedMonthName} ${year}`;
    };


  const formattedDate = formatDateWithLanguage(originalDate);
        
    return (
    <Box
      sx={{
        minHeight: "282px",
        border: `1px dashed ${theme?.details?.detailsBorderColor}`,
        backgroundColor: theme?.details?.detailsBgColor,
        borderRadius: "16px",
        p: 2,
        width:"100%"
      }}
    >
      <Typography
        sx={{ color: theme?.details?.detailsHeaderColor, fontSize:{md:"24px",sm:"20px",xs:'16px'},fontWeight:"medium" }}
            >
                {WidgetSetting?.find((item) => item?.tag == 'reservationDetails')?.data}
          </Typography>
          <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ color: theme?.details?.detailsCompanyNameColor, fontWeight: "medium", fontSize: {md:"22px",sm:"19px",xs:'16px'} }}
        >
                     {widgetConfig?.company?.company
}
        </Typography>
        <Typography sx={{ color: theme?.details?.detailsAddressColor, fontSize: "10px" }}>
                    {widgetConfig?.company?.address}  
        </Typography>
        <Typography sx={{ color: theme?.details?.detailsAddressColor, fontSize: "10px" }}>
                    {widgetConfig?.company?.zipcode} {widgetConfig?.company?.city}      
        </Typography>
   
      </Box>
     
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 4,
          mb: 2,
        }}
      >
        <Box sx={{ width: "24px", height: "24px" }}>
         <AccessTimeIcon sx={{width: "100%", height: "100%",color:theme?.details?.detailsDateIconColor}} />
        </Box>
        <Typography sx={{ color: theme?.details?.detailsDateTextColor, fontSize: "14px" }}>
                  {formattedDate} {time.substring(0, 5)}
        </Typography>
      </Box>
      <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Box sx={{ width: "24px", height: "24px" }}>
     
          <GroupIcon sx={{width: "100%", height: "100%",color:theme?.details?.detailsGuestsIconColor}} />
        </Box>
        <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
        <Typography sx={{ color: theme?.details?.detailsGuestsTextColor, fz: "14px" }}>
        {adult > 0 && `${adult} ${widgetConfig?.fields?.field1Text}`}
      {kid > 0 && `, ${kid} ${widgetConfig?.fields?.field3Text}`}
      {child > 0 && `, ${child} ${widgetConfig?.fields?.field2Text}`}
      {toddler > 0 && `, ${toddler} ${widgetConfig?.fields?.field5Text}`}
      {baby > 0 && `, ${baby} ${widgetConfig?.fields?.field4Text}`}
        </Typography>

        </Box>
      </Box>
      <Box>
     
       {reservType?.totalCost > 0 &&  <Typography sx={{color:theme?.details?.detailsPriceColor,fontWeight: "medium"}}>&euro;{ parseFloat(reservType?.totalCost ).toFixed(2)}</Typography>}
      </Box>
    
      </Box>
      {selectedExtraProduct.length > 0 && <Box sx={{ mt: 2 }}>
        <Box sx={{display:'flex',gap:'0.6rem',alignItems:'center'}}>
          <ProductionQuantityLimitsIcon sx={{ color: theme?.details?.detailsProductsIconColor}} />
          <Typography
          sx={{ color:theme?.details?.detailsProductsHeaderTextColor, fontWeight: "medium", fontSize: {md:"16px"} }}
        >
        {
languageWords['ExtraProducts']
}
        </Typography>
        </Box>
              {
  selectedExtraProduct?.length > 0 && (
    <>
    <Typography sx={{ color: theme?.details?.detailsProductsTextColor, fontSize: "14px",ml:4,mt:1 }}>
      {selectedExtraProduct.map((item, index) => (
        <Box sx={{display: 'flex' ,justifyContent:'space-between',alignItems:'center'}}>
        <Box>
          <span key={item.idproduct}>
              {item.quantity}x {item.productName}
          {index < selectedExtraProduct.length - 1 && <br />} {/* Add comma except for the last item */}
        </span>
        {
          item?.quantity > 1 && 
          <span style={{color: theme?.details?.detailsPriceColor, fontSize: "14px"}}>
           ( {item?.quantity} x &euro; {parseFloat(item?.price).toFixed(2)})
          </span>
        }
        </Box>
          {reservType?.totalCost > 0 && <span style={{color: theme?.details?.detailsPriceColor, fontSize: "14px"}}>
          &euro; {parseFloat(item?.quantity * item?.price).toFixed(2)}
          </span>}
          
        </Box>
      ))}
    </Typography>
   
    </>
  )
}     
      </Box>}
      {reservType?.totalCost > 0 && <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: theme?.details?.detailsReservationTypeTextColor, fontWeight: "bold" }}
        >
         {reservType?.name}
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: theme?.details?.detailsPriceColor, fontWeight: "bold" }}
                >
                    €{parseFloat((reservType?.totalCost) + totalSum ).toFixed(2)}
        </Typography>
      </Box>}
     {Object.keys(bowling).length > 0 &&  <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#0B5753", fontWeight: "bold" }}
        >
        {bowling?.name}
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#000000", fontWeight: "bold" }}
        >
        {bowling.value}
        </Typography>
      </Box>}
    </Box>
  );
};

export default YourBookingCard;
