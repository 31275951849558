import * as React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'; // Import plugin for custom format parsing
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, InputLabel, useTheme } from '@mui/material';

// Enable custom format parsing
dayjs.extend(customParseFormat);

export default function DatePickerComponent({ value, label, name, onChange, error, helperText }) {
  const theme = useTheme();
  const [values, setValues] = React.useState(value ? dayjs(value, 'DD-MM-YYYY') : dayjs());

  React.useEffect(() => {
    if (values && dayjs.isDayjs(values)) {
      onChange(name, values.format('DD-MM-YYYY')); // Ensure the value is passed in the desired format
    }
  }, [values]);

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <InputLabel
        sx={{
          mb: 2,
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme?.details?.formLabelColor,
          mt: 1,
        }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            name={name}
            value={values}
            onChange={(newValue) => {
              setValues(newValue ? dayjs(newValue, 'DD-MM-YYYY') : null);
            }}
            format="DD-MM-YYYY" // Specify the desired display format
            fullWidth
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}

