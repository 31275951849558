import React, { startTransition, useEffect, useState } from "react";
import HorecaWidget from "./horecaWidget/HorecaWidget";
import ReservationWidget from "./reservationWidget/ReservationWidget";
import { Outlet, useLocation } from "react-router-dom";
import { FetchWidgetConfigDetails, getLanguageDetails, getWidgetSetUpPosition } from "../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import FeedbackComponent from "./feedbackWidget/FeedbackComponent";
import { updateButtonPosition, updateKey, updateResType, updateWidgetPosition, updateWidgetSetupId, updateFloating, updateWidgetConfig, updateLanguageData } from "../store/slice/widgetSetting.slice";
import TicketingMain from "./ticketingWidget/TicketingMain";
import EventWidget from "./eventWidget/EventWidget";
import RentalWidget from "./rentalWidget/RentalWidget";
import BowlingWidget from "./bowlingWidget/BowlingWidget";
import ActivityWidget from "./ActivitiesWidget/ActivitiesWidget";
import LessonsWidget from "./lessonsWidget/LessonsWidget";
import SalonWidget from "./salonWidget/SalonWidget";
import { updateDefaultLanguage } from "../store/slice/Addguest.slice";
import WebshopMain from "./WebshopWidget/WebshopMain";

const WidgetDynamicLoading = () => {
  const dispatch=useDispatch()
  const location = useLocation();
  const {
  widgetSetupId,
  defaultLanguage,
  key,
  buttonPosition,
  resType,
      widgetPosition,
  floating

  }=useSelector((store) => store?.widgetSettings)
  const{isBackToHoreca}=useSelector((store) => store?.addGuest)

  const fetchWidgetSetupPosition=async(widgetSetupId)=>{
    try {
      const response=await getWidgetSetUpPosition(widgetSetupId)
      if(response?.status == 200){
        startTransition(() => {
          dispatch(updateWidgetPosition(response?.data?.positionButton));
        });
      }
    } catch (error) {
      console.error("Error fetching widget settings:", error?.message);  
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const setupId = queryParams.get("widgetsetupid");
    const keyParam = queryParams.get("key");
    const langParam = queryParams.get("lang");
    const buttonPositionParam = queryParams.get("buttonposition");
    const resTypeParam = queryParams.get("resType");
    const floatingParam = queryParams.get("floating");
    fetchWidgetSetupPosition(setupId)
    localStorage.setItem(
      'widget',
      JSON.stringify({
        id: setupId,
        key: keyParam,
        lang: langParam,
      })
    );
    dispatch(updateWidgetSetupId(setupId))
    dispatch(updateKey(keyParam))
    dispatch(updateButtonPosition(buttonPositionParam))
      dispatch(updateResType(resTypeParam))
      dispatch(updateFloating(floatingParam))
  }, [location]);

    if (key && resType.toLowerCase() === "horeca") {
  return (
    <div>
      <HorecaWidget
            widgetSetupId={widgetSetupId}
            buttonPosition={buttonPosition}
            resType={resType}
            widgetPosition={widgetPosition}
            floating={floating}
            
      />
    </div>
  );
    } else if (key && resType.toLowerCase() === "feedback") {
  return (
    <div>
      <FeedbackComponent 
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
      />

    </div>
  );
}
    else if (key && resType.toLowerCase() === "ticketing") {
  return (
    <div>
      <TicketingMain 
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "event") {
  return (
    <div>
      <EventWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "rental") {
  return (
    <div>
      <RentalWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "bowling") {
  return (
    <div>
      <BowlingWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "activity") {
  return (
    <div>
      <ActivityWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "lesson") {
  return (
    <div>
      <LessonsWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
    else if (key && resType.toLowerCase() === "salon") {
  return (
    <div>
      <SalonWidget
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
             floating={floating}
      />
    </div>
  );
}
else if (key && resType.toLowerCase() === "webshop") {
  return (
    <div>
      <WebshopMain
            widgetSetupId={widgetSetupId}
            buttonPosition={buttonPosition}
            resType={resType}
            widgetPosition={widgetPosition}
            floating={floating}
            
      />
    </div>
  );
}
 else {
  return (
    <div>
      {/* Outlet for nested routes */}
      <Outlet />
    </div>
  );
}
};


export default WidgetDynamicLoading;
