import { Backdrop, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import success from "../../assets/SuccessAct.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useLocation, useNavigate } from 'react-router-dom';
import Paymentfailure from '../horecaWidget/Paymentfailure';
import PaymentPending from '../horecaWidget/PaymentPending';

import { FetchWidgetConfigDetails, getReservationPdf } from '../../api/HorecaWidget.service';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageWords } from '../../store/slice/Addguest.slice';
import { saveAs } from 'file-saver';

const PaymentSuccessActivity = () => {
    const {languageWords} = useSelector((store) => store.addGuest);
      const{resType,widgetSetupId}=useSelector((store)=>store?.widgetSettings)
    const [pdfData, setPdfData] = useState(null); // Store the PDF data here
    const [reservationNumber,setReservationNumber]=useState('')
    const [widgetLoading, setWidgetLoading] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch=useDispatch()
    const queryParams = new URLSearchParams(location.search);
    const secretkey = queryParams.get('secretkey');
    const key = queryParams.get('key');
    const widgetData=JSON.parse(localStorage?.getItem('widget')) 
   const fetchWidgetSettingsDetails = async (key,lang,id) => {
    try {
        setWidgetLoading(true);
        const response = await FetchWidgetConfigDetails(key, id, lang);
        const result = response?.basicLanguages?.reduce((acc, item) => {
            const tag = item.tag.replace(/{|}/g, "").replace(/\s+/g, "");
            const data = item.widgetTranslateLanguages[0]?.name;
            acc[tag] = data; // Assign data to the key acc[tag]
            return acc;
        }, {});
        dispatch(setLanguageWords(result));
        setWidgetLoading(false);
    } catch (error) {
        console.error("Error fetching widget settings details:", error);
        setWidgetLoading(false);
    }
};
const handleClose = () => {
    setWidgetLoading(false);
  };
    // Function to fetch the reservation PDF
    const fetchReservationPdf = async () => {
        try {
            const base64Data = await getReservationPdf(key, secretkey);
            if (base64Data) {
                setPdfData(base64Data?.pdf); // Store base64 data in state if it exists
                setReservationNumber(base64Data?.reservationNumber); // Store reservation number in state if it exists
            } else {
                console.error("No PDF data received. Check API response or parameters.");
            }
        } catch (error) {
            console.error('Error fetching PDF data:', error);
        }
    };

    const send = (frameHeight, frameWidth, top, right, bottom, left, floating, redirectUrl) => {
        window.parent.postMessage({
            reservationIframeHeight: frameHeight,
            reservationIframeWidth: frameWidth,
            reservationIframePositionTop: top,
            reservationIframePositionRight: right,
            reservationIframePositionBottom: bottom,
            reservationIframePositionLeft: left,
            reservationIframePositionFloating: floating,
            redirectUrl: redirectUrl
        }, '*');
    };

    // Call this function only when you want to download the PDF
    // const downloadPdf = () => {
    //     if (!pdfData) return;

    //     const sanitizedBase64String = pdfData.replace(/\s/g, '');
    //     const isValidBase64 = /^[A-Za-z0-9+/=]+$/.test(sanitizedBase64String);

    //     if (!isValidBase64) {
    //         console.error('Invalid base64 string:', sanitizedBase64String);
    //         return;
    //     }

    //     try {
    //         const binaryData = atob(sanitizedBase64String);
    //         const byteArray = new Uint8Array(binaryData.length);
    //         for (let i = 0; i < binaryData.length; i++) {
    //             byteArray[i] = binaryData.charCodeAt(i);
    //         }

    //         const blob = new Blob([byteArray], { type: 'application/pdf' });
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(blob);
    //         link.download = 'reservation.pdf';
    //         link.click();
    //     } catch (error) {
    //         console.error('Error decoding base64 string:', error);
    //     }
    // };
    const downloadPdf = () => {
        if (!pdfData) return;
   
        const sanitizedBase64String = pdfData.replace(/\s/g, '');
        const isValidBase64 = /^[A-Za-z0-9+/=]+$/.test(sanitizedBase64String);
   
        if (!isValidBase64) {
            console.error('Invalid base64 string:', sanitizedBase64String);
            return;
        }
   
        try {
            const binaryData = atob(sanitizedBase64String);
            const byteArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                byteArray[i] = binaryData.charCodeAt(i);
            }
   
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            saveAs(blob, 'reservation.pdf');  // This should work on iOS as well
        } catch (error) {
            console.error('Error decoding base64 string:', error);
        }
   };

    // Fetch the reservation PDF data only if key and secretkey are provided
    useEffect(() => {
        if (key && secretkey) {
            fetchReservationPdf();
        }
    }, [key, secretkey]);

    useEffect(()=>{
        fetchWidgetSettingsDetails(widgetData?.key,widgetData?.lang,widgetData?.id);
    },[])


    // if(!widgetSetupId){
    //    return <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:"100vh",color:"grey",fontSize:'2rem'}}>Dont have access to this page.</Box>
    //  }
    if(widgetLoading){
        return (
          <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={widgetLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        )
      }

    if (!key || !secretkey) {
        return <Paymentfailure />;
    }
    if (key && secretkey === "pending") {
        send("100vh", "100%", "", "0px", "0px", "", "fixed");
        return <PaymentPending />;
    }

    else if (key && secretkey !== "cancel") {
        send("100vh", "100%", "", "0px", "0px", "", "fixed");
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    p: { xs: 1, sm: 2, md: 3 }, // Responsive padding
                }}>
                    <Box sx={{
                        width: { xs: '200px', sm: '250px', md: '300px' },
                        height: { xs: '150px', sm: '200px', md: '250px' },
                        mb: 2,
                    }}>
                        <img src={success} alt="success-img" style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Typography sx={{
                        color: theme?.widget?.successHeader,
                        fontSize: { xs: '20px', sm: '28px', md: '36px' },
                        fontWeight: '600',
                        mb: 1,
                    }}>
                       {languageWords?.ReservationSuccessMessage
}
                    </Typography>
                    <Typography sx={{
                        color: theme?.widget?.successText,
                        fontSize: { xs: '10px', sm: '11px', md: '12px' },
                        mb: 3,
                    }}>
                      {languageWords?.ReservationSuccessInfo}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: '1rem',
                        alignItems: 'center',
                    }}>
                        <Typography sx={{
                            color: theme?.widget?.successTextBold,
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: '500',
                        }}>
                            {languageWords?.reservationNumberText}
                        </Typography>
                        <Typography sx={{
                            color: theme?.widget?.successResNumber,
                            fontSize: { xs: '28px', sm: '36px', md: '40px' },
                            fontWeight: '500',
                        }}>
                          {reservationNumber}
                        </Typography>
                    </Box>
                </Box>

                {/* Conditional Download Button */}
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                   
                    justifyContent: 'space-around',
                    position: 'fixed',
                    alignItems: 'center',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: { xs: 1, sm: 2 },
                    boxShadow: 1,
                }}>
                          <Button
        variant="contained"
                        onClick={() => send("0px", "0px", "", "0px", "0px", "", "fixed", "/")}
    // onClick={() => navigate(-1)}
        sx={{
          backgroundColor: "grey",
          color: "white",
          fontSize: { xs: '10px', sm: '12px' }, // Responsive font size
          display: 'flex',
          alignItems: 'center',
          flexGrow: { xs: 1, md: 0 }, // Allow button to grow on small screens
          mb: { xs: 1, md: 0 }, // Margin bottom for smaller screens
        }}
      >
        
       {languageWords?.BackToHome}
      </Button>
                    {pdfData && (
                        <Button
                            startIcon={<FileDownloadIcon />}
                            sx={{
                                backgroundColor: theme?.widget?.successBtnBgColor,
                                color: theme?.widget?.successBtnText,
                                fontSize: { xs: '10px', sm: '12px' },
                                flexGrow: { xs: 1, md: 0 },
                                mb: { xs: 1, md: 0 },
                                "&:hover": {
                                    backgroundColor: theme?.widget?.successBtnBgHover,
                                    color: theme?.widget?.successBtnTextHover,
                                }
                            }}
                            onClick={downloadPdf}
                        >
                           {languageWords?.DownloadPdf}
                        </Button>
                    )}
                </Box>
            </>
        );
    }
    else {
        send("100vh", "100%", "", "0px", "0px", "", "fixed");
        return <Paymentfailure />;
    }
};

export default PaymentSuccessActivity;

