import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import burger from "../../assets/burger.png";
import fries from "../../assets/fries.png";
import drinks from "../../assets/drinks.png";
import icecream from "../../assets/icecream.png";
import deals from "../../assets/deals.png";
import footerImg from "../../assets/footerLogo.png";
import coffee from "../../assets/coffee.png";
import HorizontalMenuItems from "./HorizontalMenuItem";
import SelectMenuItemComponent from "./SelectMenuItemComponent";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MenuItems = [
  {
    id: 1,
    name: "Burger",
    imgItem: burger,
  },
  {
    id: 2,
    name: "Fries",
    imgItem: fries,
  },
  {
    id: 3,
    name: "Drinks",
    imgItem: drinks,
  },
  {
    id: 4,
    name: "Ice Cream",
    imgItem: icecream,
  },
  {
    id: 5,
    name: "Deals",
    imgItem: deals,
  },
  {
    id: 6,
    name: "Coffee",
    imgItem: coffee,
  },
];

const Menubar = () => {
  const {webShopMenuPosition}=useSelector((store)=>store?.widgetSettings)
  const position = webShopMenuPosition;
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItems[0]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);

  // Function to handle scrolling to the next items
  const handleScroll = () => {
    setScrollOffset(scrollOffset + 120); // Scroll by 120px (adjustable)
  };
  return (
    <>
      {position == "vertical" ?
       (
        <Box
          sx={{
            width: "120px",
            backgroundColor: "#fff",
            height: "auto",
            pt: 2,
            display: "flex", // Add flexbox
            flexDirection: "column", // Stack children vertically
            justifyContent: "space-between", // Ensure space between header, content, and footer
          }}
        >
          <Box>
            {MenuItems?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft:
                    selectedMenuItem === item?.name
                      ? "3px solid #F37120"
                      : "transparent",
                }}
                onClick={() => setSelectedMenuItem(item?.name)}
              >
                <Box
                  sx={{
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 80,
                    height: 80,
                    backgroundColor:
                      selectedMenuItem === item?.name
                        ? "#F37120"
                        : "transparent",
                    borderRadius: "16px",
                  }}
                >
                  <img
                    src={item.imgItem}
                    alt={item.name}
                    width="50"
                    height="50"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color:
                        selectedMenuItem === item?.name ? "#fff" : "#969BA0",
                      fontSize: "11px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Always keep it row
              justifyContent: "flex-end",
              alignItems: "flex-end",
              px: { xs: 2, md: 4 },
              py: 2,
            }}
          >
            <Box
              sx={{
                flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
          </Box>
        </Box>
      )
      : position == "horizontal" ? (
        <HorizontalMenuItems MenuItems={MenuItems} />
      )
      :
        <SelectMenuItemComponent />
      }
      
    </>
  );
};

export default Menubar;
