import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ReservationCancelResponse = () => {
    const{languageWords}=useSelector((store)=>store?.addGuest)
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Check if the screen is small or extra-small

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100vh" }}>
       
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <CheckCircleIcon sx={{ width: 150, height: 150, color: 'grey' }} />
                    <Typography 
                        sx={{ 
                            color: "grey", 
                            fontWeight: 'semiBold', 
                            fontSize: { xs: '24px', sm: '30px', md: '36px' }, // Responsive font sizes
                            lineHeight: '2rem',
                            mt: 3,
                            mb: 1 
                        }}
                    >
                      {languageWords?.ReservationCancelSuccessMessage} 
                    </Typography>
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" sx={{backgroundColor:"#F37120"}} size="large" onClick={() => navigate(-1)}>
                        {languageWords?.BackToHome}  
                        </Button>
                    </Box>
                </Box>
           
        </Box>
    );
}

export default ReservationCancelResponse;