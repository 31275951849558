import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Popper,
  Popover,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Make sure locale is available for formatting
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestCount,
  setReservationTypeDatas,
  updateReservationGroup,
  updateReservationType,
  updateSelectedDate,
  updateTime,
  updateTotalCount,
  setReservationGroupDatas,
  setFreeTablesData,
  setIsBackToHoreca,
} from "../../store/slice/Addguest.slice";
import { getCalenderDatas } from "../../api/HorecaWidget.service";
import { updateResType } from "../../store/slice/widgetSetting.slice";

const Calendar = ({ isOpen, handleClose, Ref }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    totalCount,
    selectedDate,
    reservType,
    time,
    reservGroup,
    reservationTypeDatas,
    reservationGroupData,
    freeTablesData,
    dateLoading,
    languageWords,
  } = useSelector((store) => store?.addGuest);

  const { blockedDates } = useSelector((store) => store?.widgetSettings);
  const { selectedDates } = blockedDates ?? "";
  const today = dayjs(); // Get the current date
  const [currentDate, setCurrentDate] = useState(today);
  const daysOfWeek = [
    languageWords?.monday?.slice(0, 2),
    languageWords?.Tuesday?.slice(0, 2),
    languageWords?.Wednesday?.slice(0, 2),
    languageWords?.Thursday?.slice(0, 2),
    languageWords?.Friday?.slice(0, 2),
    languageWords?.Saturday?.slice(0, 2),
    languageWords?.Sunday?.slice(0, 2),
  ];
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = (currentDate.startOf("month").day() + 6) % 7;
  const isToday = (day) => dayjs().isSame(currentDate.date(day), "day");
  const isPastDay = (day) => currentDate.date(day).isBefore(today, "day");
  const isBlockedDate = (day) =>
    selectedDates?.includes(currentDate?.date(day)?.format("YYYY-MM-DD"));
  //const isSelected = (day) => currentDate.date(day).isSame(currentDate, 'day');
  const selectedDay =
    selectedDate.trim() != "" ? dayjs(selectedDate, "DD-MM-YYYY") : null;
  const isSelected = (day) =>
    selectedDay && currentDate.date(day).isSame(selectedDay, "day");
  const dispatch = useDispatch();
  const greatestDate =selectedDates ? dayjs(selectedDates[selectedDates?.length - 1]) : "" ; // Get the greatest date
const isNextMonthDisabled = () => {
  // Disable if the greatestDate's month is the same as currentDate's month
  if(selectedDates)
  return greatestDate?.isSame(currentDate, "month");
};

const handleNextMonth = () => {
  if (!isNextMonthDisabled()) {
    setCurrentDate(currentDate.add(1, "month"));
  }
};

  



  const handlePrevMonth = () => {
    // setCurrentDate(currentDate.subtract(1, 'month'));
    if (currentDate.isAfter(today.startOf("month"))) {
      setCurrentDate(currentDate.subtract(1, "month"));
    }
  };

  // const handleNextMonth = () => {
  //   setCurrentDate(currentDate.add(1, "month"));
  // };

  const handleDateSelect = (day) => {
    if (!isPastDay(day) && isBlockedDate(day)) {
      dispatch(setIsBackToHoreca(false));
      if (reservationTypeDatas?.length > 0) {
        dispatch(setReservationTypeDatas([]));
      }
      if (Object?.keys(reservType)?.length > 0) {
        dispatch(updateReservationType({}));
      }
      if (reservationGroupData?.length > 0) {
        dispatch(setReservationGroupDatas([]));
      }
      if (Object?.keys(reservGroup)?.length > 0) {
        dispatch(updateReservationGroup({}));
      }
      if (freeTablesData?.length > 0) {
        dispatch(setFreeTablesData([]));
      }
      if (time?.trim() != "") {
        dispatch(updateTime(""));
      }
      setCurrentDate(currentDate.date(day));
      const selectDate = currentDate.date(day).format("DD-MM-YYYY");
      dispatch(updateSelectedDate(selectDate));
      handleClose();
    }
  };
  const getTranslatedMonth = () => {
    const monthName = currentDate?.format("MMMM");
    return languageWords?.[monthName] || monthName;
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
     
      PaperProps={{
        sx: {
          width: isSmallScreen ? "90%" : isMediumScreen ? "80%" : "80%",
          maxWidth: "430px",
        },
      }}
    >
      {dateLoading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { md: "430px", sm: "430px", xs: "100%" },
              height: 400,
              backgroundColor: theme?.calender?.bgColor,
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box
          // sx={{
          //   width: { md: "430px", sm: "100%", xs: "100%" },
          //   textAlign: "center",
          //   backgroundColor: theme?.calender?.bgColor,
          //   paddingBottom: 2,
          //   p: 1,
          // }}
          sx={{
            backgroundColor: theme?.calender?.bgColor,
            padding: isSmallScreen ? 1 : 2,
          }}
        >
          {/* Header with arrows and year/month */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
             // padding: 2,
             padding: isSmallScreen ? 1 : 2,
            }}
          >
            <IconButton
              onClick={handlePrevMonth}
              disabled={currentDate.isSame(today, "month")}
              sx={{
                color: theme?.calender?.arrowColor, // Default color for icon
                "&:hover": {
                  color: theme?.guest?.arrowColorHover, // Change color on hover
                },
                "&:active": {
                  color: theme?.calender?.arrowColorActive, // Change color when clicked (active)
                },
                "&.Mui-disabled": {
                  color: theme?.calender?.disabledArrowColor, // Change color when disabled
                },
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: theme?.calender?.yearTextColor, fontSize: isSmallScreen ? "1rem" : "1.25rem", }}
            >
              {currentDate.format("YYYY")}
            </Typography>
            <IconButton
              onClick={handleNextMonth}
              disabled={isNextMonthDisabled()}
          
              sx={{
                color: theme?.calender?.arrowColor, // Default color for icon
                "&:hover": {
                  color: theme?.guest?.arrowColorHover, // Change color on hover
                },
                "&:active": {
                  color: theme?.calender?.arrowColorActive, // Change color when clicked (active)
                },
                "&.Mui-disabled": {
                  color: theme?.calender?.disabledArrowColor, // Change color when disabled
                },
              }}
            >
              <ArrowForward />
            </IconButton>
          </Box>

          {/* Display Year and Month */}
          <Typography
            sx={{
              marginBottom: 1,
              color: theme?.calender?.monthTextColor,
              fontWeight: "bold",
              fontSize: isSmallScreen ? "1.25rem" : "1.5rem",
              textAlign: "center",
            
            }}
          >
            {" "}
            {getTranslatedMonth()}
          </Typography>

          {/* Days of the week */}
          <Grid container spacing={1} mt={3}>
            {daysOfWeek.map((day, index) => (
              <Grid item xs={1.71} key={index}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: theme?.calender?.daysTextColor,
                    fontSize: isSmallScreen ? "0.8rem" : "1rem",
                  }}
                >
                  {day}
                </Typography>
              </Grid>
            ))}
          </Grid>

          {/* Dates Grid */}
          <Grid container spacing={1} sx={{ marginTop: 1 }}>
            {/* Empty cells for days before the first day of the month */}
            {[...Array(firstDayOfMonth)].map((_, index) => (
              <Grid item xs={1.71} key={index} />
            ))}

            {/* Render all days of the month */}
            {[...Array(daysInMonth)].map((_, dayIndex) => {
              const day = dayIndex + 1;

              const isPast = isPastDay(day);
              const isSelectedDay = isSelected(day);
              const isNotBlocked = isBlockedDate(day);
              const isActive = !isPast && isNotBlocked;

              return (
                <Grid item xs={1.71} key={dayIndex}>
                  <Box
                    onClick={() => handleDateSelect(day)}
                    sx={{
                      backgroundColor:isSelectedDay && isActive ? theme?.calender?.dateBgColorActive : '',
                      border: "2px dotted",
                      borderColor:
                        isPast || !isNotBlocked
                          ? theme?.calender?.inActiveBorderColor
                          : isSelectedDay && isActive
                          ? theme?.calender?.dateTextColorActive
                          : theme?.calender?.dateBorerColor,
                      color:
                        isSelectedDay && isActive
                          ? theme?.calender?.dateTextColorActive
                          : isPast || !isNotBlocked
                          ? theme?.calender?.inActiveTextColor
                          : theme?.calender?.dateTextColor,
                      // backgroundColor: isSelectedDay && isActive  ? theme?.calender?.dateBgColorActive :isPast || !isNotBlocked ? theme?.calender?.inactiveBgColor : theme?.calender?.dateBgColr,
                      cursor: isActive ? "pointer" : "default",
                      borderRadius: "8px",
                      // padding: 1,
                      textAlign: "center",
                      transition: "background-color 0.2s ease",
                      width: "40px",
                      height: "40px",
                      fontWeight: isActive ? "bold" : "normal",
                      lineHeight: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // "&:hover": {
                      //   backgroundColor:
                      //     isPast || !isNotBlocked
                      //       ? theme?.calender?.inactiveBgColor
                      //       : theme?.calender?.dateBgHover, // Ensure hover background color
                      //   color:
                      //     isPast || !isNotBlocked
                      //       ? theme?.calender?.inActiveTextColor
                      //       : theme?.calender?.dateTextColorHover, // Ensure hover text color
                      // },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          isPast || !isNotBlocked
                            ? "#969BA0"
                            : isSelectedDay && isActive
                            ? theme?.calender?.dateTextColorActive
                            : theme?.calender?.dateTextColor,
                        fontSize: "22px",
                      }}
                    >
                      {day}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Popover>
  );
};

export default Calendar;
