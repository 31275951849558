import { Box, Typography } from "@mui/material";
import React from "react";
import RatingComponent from "../../components/RatingComponent";
import TextAreaComponent from "../../components/Input/TextAreaComponent";
import { setAddFields } from "../../store/slice/feedback.slice";

const QuestionAnswerComponent = ({data,name}) => {
  return (
  
      <Box
        sx={{
          width: {xs:"100%"},
          height: "100%",
          borderRadius: "10px",
          border: "1px solid #EBEBEB",
          backgroundColor: "#FDFDFD",
          p:2,
          mt:2,
          pb:3
          

        }}
      >
        <Box>
        <Typography sx={{color:"#000000",fontSize:{md:"20px",sm:"16px",xs:"14px"},fontWeight:"medium"}}>{data?.name}</Typography>
        <Typography sx={{color:"#969BA0",fontSize:{md:"16px",sm:"14px",xs:"12px"},fontWeight:"regular",mt:1}}>
        {data?.info}
        </Typography>
        </Box>
        <Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <RatingComponent data={data} name={data?.name?.replace(/\s+/g, '')} setField={setAddFields}/>
             
            </Box>
        <Box>
        </Box>
  
      </Box>
    </Box>
    
  );
};

export default QuestionAnswerComponent;
