import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid2 from "@mui/material/Grid2";
import YourBookingCard from "./YourBookingCard";
import PaymentCard from "./PaymentCard";
import extraImg from "../../assets/extra.png";
import { getExtraProductDetails } from "../../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import { setExtraProductData, setQuantities, setSelectedExtraProduct, updateReservationType } from "../../store/slice/Addguest.slice";

const Extra = () => {
  const theme=useTheme()
  const dispatch = useDispatch();
  const { reservType, selectedExtraProduct, extraProductData,quantities,languageWords} = useSelector((store) => store.addGuest);
  const [isShowMore, setIsShowMore] = useState(false);
  const productsToDisplay =
    extraProductData?.length > 0
      ? isShowMore
        ? extraProductData
        : extraProductData?.slice(0, 2)
      : [];

  const handleProductSelect = (item) => {
    const isSelected = selectedExtraProduct?.some((product) => product.idproduct === item.idproduct);
    const currentQuantity = quantities[item.idproduct] || 0;
    const newQuantity = isSelected ? 0 : currentQuantity + 1;

    if (isSelected) {
      dispatch(setSelectedExtraProduct(selectedExtraProduct.filter((product) => product.idproduct !== item.idproduct)));
      // const newTotalCost = reservType?.totalCost - item?.price * currentQuantity;
      // dispatch(updateReservationType({ ...reservType, totalCost: newTotalCost }));
    } else {
      dispatch(setSelectedExtraProduct([...selectedExtraProduct, { ...item, quantity: newQuantity }]));
      // const newTotalCost = reservType?.totalCost + item?.price * newQuantity;
      // dispatch(updateReservationType({ ...reservType, totalCost: newTotalCost }));
    }

    dispatch(setQuantities({ ...quantities, [item.idproduct]: newQuantity }));
  };

  const handleQuantityChange = (item, increment) => {
    const currentQuantity = quantities[item.idproduct] || 0;
    const newQuantity = Math.max(currentQuantity + (increment ? 1 : -1), 0); // Prevent negative quantity
  
    dispatch(setQuantities({ ...quantities, [item.idproduct]: newQuantity }));
    // Update selectedExtraProduct
    if (newQuantity === 0) {
      // Remove the item if quantity is zero
      dispatch(setSelectedExtraProduct(selectedExtraProduct.filter((product) => product.idproduct !== item.idproduct)));
    } else {
      // Update or add the item with the new quantity
      const updatedSelectedProducts = selectedExtraProduct.map((product) => {
        if (product.idproduct === item.idproduct) {
          return { ...product, quantity: newQuantity }; // Update quantity
        }
        return product; // Return other products unchanged
      });
  
      // If item was not previously selected, add it
      if (!updatedSelectedProducts.some((product) => product.idproduct === item.idproduct)) {
        updatedSelectedProducts.push({ ...item, quantity: newQuantity });
      }
  
      dispatch(setSelectedExtraProduct(updatedSelectedProducts));
    }
  };
  

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 size={{ md: 7, sm: 12, xs: 12 }}>
          {productsToDisplay?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "1rem",
                border: "1px solid #EBEBEB",
                p: 2,
                borderRadius: "5px",
                backgroundColor: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.bgActive: theme?.product?.bgColor,
                mb: 2,
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                width: "100%",
                cursor: "pointer",
               
              }}
            >
           
              <Box sx={{width:"100%"}}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                  <Typography
                    sx={{
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.headerActive : theme?.product?.headerColor,
                      fontSize: { md: "20px", sm: "14px" },
                      fontWeight: "semiBold",
                    }}
                  >
                    {item?.languages[0]?.data}
                  </Typography>
                  <Typography
                    sx={{
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.priceColorActive : theme?.product?.priceColor,
                      fontSize: { md: "20px", sm: "14px" },
                      fontWeight: "semiBold",
                    }}
                  >
                    €{parseFloat(item?.price).toFixed(2)}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.itemTextActive : theme?.product?.itemText,
                    fontSize: "12px",
                  }}
                >
                  {item?.languages[1]?.data}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center",justifyContent:'flex-end', mt: 2, gap: "1rem" }}>
                  <Button
                    onClick={() => handleQuantityChange(item, false)}
                    disabled={(quantities[item.idproduct] || 1) === 1} // Disable if quantity is 0
                    sx={{ minWidth: "30px",color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.addBtnTextActive:theme?.product?.addBtnTextColor,
                    "&:hover":{
                  
                      color:theme?.product?.addBtnTextHover
                      
                    } }}
                  >
                    -
                  </Button>
                  <Typography sx={{color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : theme?.product?.addMinTextColor}}>{quantities[item.idproduct] || 1}</Typography>
                  <Button onClick={() => handleQuantityChange(item, true)} sx={{ minWidth: "30px",color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ?  theme?.product?.addBtnTextActive:theme?.product?.addBtnTextColor,
                  "&:hover":{
                  
                    color:theme?.product?.addBtnTextHover
                    
                  }
                }}>
                    +
                  </Button>
                  <Button
                    onClick={() => handleProductSelect(item)}
                    sx={{
                      backgroundColor: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.addBtnActive : theme?.product?.addBtnBg,
                      fontSize: "12px",
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? theme?.product?.addBtnTextActive:theme?.product?.addBtnTextColor,
                      width: "100px",
                      "&:hover":{
                        backgroundColor: theme?.product?.addBtnBgHover,
                        color:theme?.product?.addBtnTextHover
                        
                      }
                    }}
                  >
                    {selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? languageWords?.Remove : languageWords?.Add}
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}

          {extraProductData?.length > 2 && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              sx={{
                backgroundColor: theme?.product?.addBtnBg,
                fontSize: "14px",
                color: theme?.product?.addBtnTextColor,
                width: "140px",
                py: 1,
                "&:hover":{
                  backgroundColor: theme?.product?.addBtnBgHover,
                  color:theme?.product?.addBtnTextHover
                  
                }
              }}
              onClick={() => setIsShowMore(!isShowMore)}
            >
              {isShowMore ? languageWords?.Showless : languageWords?.Showmore}
            </Button>
          </Box>}
        </Grid2>
        <Grid2 size={{ md: 5, sm: 12, xs: 12 }}>
          <YourBookingCard />
       {reservType?.totalCost > 0 && <PaymentCard />}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Extra;


